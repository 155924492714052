import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';
import { FymButton } from '../../../components/FymButton';
import { screenshotAsset } from '../../../components/screenshotNextAsset';

import SeeYourStatsImage from '../../../../public/statics/images/stats/see-your-stats.png';
import { AvailableLanguages } from '../../../redux/appTypes';
import { IndexReviewsSecureTrusted } from './IndexReviewsSecureTrusted';

export const SelectPlaylistsScreenshot: React.FC = React.memo(() => {
  const { locale } = useRouter();
  const intl = useIntl();
  return (
    <Image
      src={screenshotAsset('mobile-screenshot-1-dark', locale as AvailableLanguages | undefined)}
      alt={intl.formatMessage({
        id: 'web.home.main-functionality.select-playlists-image-alt',
        defaultMessage: 'FreeYourMusic app select playlist source screen'
      })}
      placeholder="blur"
      className="img-fluid"
      style={{
        maxWidth: '100%',
        height: 'auto'
      }}
    />
  );
});
export const StatsScreenshot: React.FC = React.memo(() => {
  const intl = useIntl();
  return (
    <Image
      src={SeeYourStatsImage}
      alt={intl.formatMessage({
        id: 'web.home.main-functionality.stats-image-alt',
        defaultMessage: 'FreeYourMusic Stats screen'
      })}
      placeholder="blur"
      className="img-fluid"
      style={{
        maxWidth: '100%',
        height: 'auto'
      }}
    />
  );
});
export const IndexMainFunctionality: React.FC = React.memo(() => {
  return (
    <>
      <Container className="text-center my-5 pt-5">
        <Row className="my-5 pt-5">
          <Col md={4}>
            <div className="px-5 mx-5 mx-md-0">
              <SelectPlaylistsScreenshot />
            </div>
          </Col>
          <Col
            md={8}
            className="text-center text-md-start d-flex flex-column align-items-center align-items-md-start justify-content-center"
          >
            <p className="smaller-header mt-5">
              <FormattedMessage
                id="web.how-to.sync.title-allMusic"
                defaultMessage="Transfer your music library and more!"
              />
            </p>
            <p className="lead mt-1 mb-5">
              <FormattedMessage
                id="web.home.main-functionality.description1"
                defaultMessage="Transfer the music you love to a new streaming service in a few taps.{br}FreeYourMusic - the fastest solution to moving your playlists."
                values={{ br: <br /> }}
              />
            </p>

            <Link prefetch={false} href="/download" passHref>
              <FymButton>
                <FormattedMessage id="web.home.main-functionality.button2" defaultMessage="Create a transfer" />
              </FymButton>
            </Link>
          </Col>
        </Row>

        <Row className="my-5 py-5">
          <Col md={12}>
            <IndexReviewsSecureTrusted />
          </Col>
        </Row>
      </Container>
    </>
  );
});
