import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import Image from 'next/image';
import StatsTwoPhonesImage from '../../../../public/statics/images/stats/two-phones.png';

export const StatsSpotifyWrapped: React.FC = () => {
  return (
    <>
      <Container className="text-center my-5 py-5">
        <Row>
          <Col md={6} className="d-flex align-items-center">
            <div className="d-flex align-items-center p-5">
              <Image src={StatsTwoPhonesImage} alt="" className="img-fluid" width={650} placeholder="blur" />
            </div>
          </Col>
          <Col
            md={6}
            className="text-center text-md-start d-flex flex-column align-items-center align-items-md-start justify-content-center"
          >
            <p className="smaller-header">
              <FormattedMessage
                id="web.stats.spotify-wrapped.title"
                defaultMessage="It’s just like Spotify Wrapped... but for various services"
              />
            </p>
            <p className="lead my-1">
              <FormattedMessage
                id="web.stats.spotify-wrapped.content.1"
                defaultMessage="Get your personalized statistics from the streaming services you use, presented in a visually attractive way."
              />
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};
