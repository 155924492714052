import { GetStaticProps } from 'next';
import { getIntlProps } from '@moxy/next-intl';
import { StatsPage } from '../../src/containers/pages/StatsPage';

// eslint-disable-next-line import/no-default-export
export default StatsPage;

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: await getIntlProps(locale)
});
