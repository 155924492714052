import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from 'reactstrap';
import { DownloadButtons } from '../../../components/DownloadButtons';
import { TopComponent } from '../components/TopComponent';
import { HeaderTitle } from '../components/HeaderTitle';
import { MobileDesktopScreenshot } from '../components/MobileDesktopScreenshot';

const TopContainerComponent: React.FC<React.PropsWithChildren<any>> = React.memo(({ children, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Container fluid {...props}>
      {children}
    </Container>
  );
});
export const StatsTop: React.FC = () => {
  return (
    <TopComponent ContainerClass={TopContainerComponent}>
      <div className="d-flex flex-row justify-content-center my-5 pt-0 pt-xl-5">
        <div className="top-component-left">
          <HeaderTitle>
            <FormattedMessage id="web.stats.top.title" defaultMessage="Check Your Music" />
          </HeaderTitle>
          <h2 className="lead">
            <FormattedMessage
              id="web.stats.top.text"
              defaultMessage="See your music statistics for Spotify, Apple Music and more!"
            />
          </h2>
          <DownloadButtons />
          <p className="mt-3 text-muted fw-medium">
            <FormattedMessage
              id="web.stats.top.no-credit-card-free-to-use"
              defaultMessage="No 💳 credit card required, get it for FREE!"
            />
          </p>
        </div>
        <MobileDesktopScreenshot
          className="top-component-right d-none d-xl-flex"
          mobileScreenshot="mobile-screenshot-7-dark"
          desktopScreenshot="desktop-screenshot-6-dark"
        />
      </div>
    </TopComponent>
  );
};
