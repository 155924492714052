import React from 'react';
import { FormattedMessage } from 'react-intl';

export const IndexReviewsSecureTrusted: React.FC = () => {
  return (
    <div className="d-flex flex-column flex-md-row top-points justify-content-center">
      <div>
        <i className="fa-solid fa-star me-3 text-gold" />
        <FormattedMessage id="web.home.top.points.5-star-reviews" defaultMessage="5k+ Five-Star Reviews" />
      </div>
      <div>
        <i className="fa-solid fa-check-circle me-3 text-gold" />
        <FormattedMessage id="web.home.top.points.secure" defaultMessage="100% Secure" />
      </div>
      <div>
        <i className="fa-solid fa-shield-check me-3 text-gold" />
        <FormattedMessage
          id="web.home.top.points.trusted-by-music-lovers"
          defaultMessage="Trusted by Over {number}m+ Music Lovers"
          values={{ number: '5' }}
        />
      </div>
    </div>
  );
};
