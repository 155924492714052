import React from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { IndexTalkingAboutUs } from './index/IndexTalkingAboutUs';
import { Footer } from '../../layouts/Footer';
import { StatsTop } from './stats/StatsTop';
import { StatsAllMusicServices } from './stats/StatsAllMusicServices';
import { StatsYourListeningStats } from './stats/StatsYourListeningStats';
import { StatsSpotifyWrapped } from './stats/StatsSpotifyWrapped';
import { StatsSafeTrustedSecure } from './stats/StatsSafeTrustedSecure';
import { StatsUpselling } from './stats/StatsUpselling';
import { IndexHowItWorksBigCTA } from './index/IndexHowItWorksBigCTA';
import { nextDynamicAsset } from '../../components/nextAsset';
import { spotify } from '../../components/musicServices/services/Spotify';
import { StatsListButton } from './StatsListButton';
import { makePageTitle } from '../../components/utils';

export const StatsPage: NextPage = React.memo(() => {
  const intl = useIntl();
  const router = useRouter();
  const { locale } = router;

  const title = makePageTitle(
    intl.formatMessage({
      id: 'web.stats.page_title',
      defaultMessage: 'Your Music Statistics'
    })
  );
  const description = intl.formatMessage({
    id: 'web.stats.page_description',
    defaultMessage:
      'See your music stats for Spotify, Apple Music, TIDAL, and many more! Look back at your listening habits at any time.'
  });
  const promoImage = nextDynamicAsset(`${locale}/statsFixed/see-your-stats-for-${spotify.id}.png`, {
    w: '1200'
  });
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="twitter:title" content={title} key="twitter-title" />
        <meta property="og:title" content={title} key="fb-title" />
        <meta itemProp="description" content={description} key="itemProp-description" />
        <meta name="description" content={description} key="description" />
        <meta property="og:description" content={description} key="fb-description" />
        <meta property="twitter:description" content={description} key="twitter-description" />
        <meta itemProp="image" content={promoImage} key="image" />
        <meta property="twitter:image" content={promoImage} key="twitter-image" />
        <meta property="twitter:image:width" content="1200" key="twitter-image-width" />
        <meta property="twitter:image:height" content="600" key="twitter-image-height" />
        <meta property="og:image" content={promoImage} key="fb-image" />
      </Head>
      <StatsTop />
      <StatsAllMusicServices />
      <StatsYourListeningStats />
      <StatsSpotifyWrapped />
      <StatsSafeTrustedSecure />
      <StatsUpselling />
      <IndexHowItWorksBigCTA className="max-auto" />
      <IndexTalkingAboutUs />
      <StatsListButton />
      <Footer />
    </>
  );
});
