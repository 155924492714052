import React from 'react';
import { Button } from 'reactstrap';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

export const StatsListButton: React.FC = () => {
  return (
    <div className="d-flex py-5 my-5 justify-content-center">
      <Link prefetch={false} href="/stats/list" passHref>
        <Button color="primary" outline style={{ maxWidth: 400 }} size="lg">
          <i className="fa-solid fa-star" />{' '}
          <FormattedMessage defaultMessage={"Check out other people's stats"} id="web.statslist.cta" />
        </Button>
      </Link>
    </div>
  );
};
