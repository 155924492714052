import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Link from 'next/link';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Image from 'next/image';
import { MusicService } from '../../../components/musicServices/services/types';
import { musicServicesForStats } from '../../../components/musicServices/allMusicServices';

const { fromAlt } = defineMessages({
  fromAlt: {
    id: 'web.see-your-stats.top.title',
    defaultMessage: 'See your stats for {musicService}'
  }
});

const StatsOptionElement: React.FC<{
  musicService: MusicService;
}> = React.memo(({ musicService }) => {
  const intl = useIntl();
  return (
    <Col xs={12} sm={6} md={6} className="stats-option-element my-4">
      <Link prefetch={false} href={`/see-your-stats-for-${musicService.id}`}>
        <div
          className="d-flex position-relative"
          style={{
            height: 80
          }}
        >
          <Image
            src={musicService.logoColor.dark}
            alt={intl.formatMessage(fromAlt, { musicService: musicService.name })}
            className="img-fluid"
            fill
            unoptimized
            sizes="100vw"
            style={{
              objectFit: 'contain',
              objectPosition: 'center'
            }}
          />
        </div>
        <div className="text-center mt-3 text-light">
          <FormattedMessage
            id="web.see-your-stats.top.title"
            defaultMessage="See your stats for {musicService}"
            values={{
              musicService: musicService.shortName
            }}
          />
          {!musicService.areStatsSupported && (
            <>
              {' '}
              <span className="coming-soon">
                <FormattedMessage id="web.top.selling-points.coming-soon" defaultMessage="Coming soon" />
              </span>
            </>
          )}
        </div>
      </Link>
    </Col>
  );
});

export const StatsOptionsLarge: React.FC = React.memo(() => {
  return (
    <Row>
      {musicServicesForStats.map((musicService, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <StatsOptionElement key={index} musicService={musicService} />;
      })}
    </Row>
  );
});

export const StatsAllMusicServices: React.FC = React.memo(() => {
  return (
    <Container className="all-music-streaming-services py-5" id="available-music-services">
      <Row>
        <Col>
          <h2 className="smaller-header text-center my-5">
            <FormattedMessage
              id="web.see-your-stats-for.all-services.title"
              defaultMessage="See your music stats by connecting to your favorite music services"
            />
          </h2>
        </Col>
      </Row>
      <StatsOptionsLarge />
    </Container>
  );
});
