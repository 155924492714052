import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import Image from 'next/image';

import StatsImage from '../../../../public/statics/images/stats/see-your-stats.png';

export const StatsYourListeningStats: React.FC = () => {
  return (
    <>
      <Container className="text-center my-5 py-5">
        <Row>
          <Col
            md={8}
            className="text-center text-md-start d-flex flex-column align-items-center align-items-md-start justify-content-center order-2 order-lg-1"
          >
            <p className="smaller-header">
              <FormattedMessage
                id="web.stats.your-listening-stats.title"
                defaultMessage="Your listening stats all year round"
              />
            </p>
            <p className="lead my-1">
              <FormattedMessage
                id="web.stats.your-listening-stats.content.1"
                defaultMessage="Look back at your listening habits any time."
              />
            </p>
            <p className="lead my-1">
              <FormattedMessage
                id="web.stats.your-listening-stats.content.2"
                defaultMessage="See your most-played songs, artists, albums, and promote your playlists."
              />
            </p>
          </Col>
          <Col md={4} className="d-flex align-items-center order-1 order-lg-2">
            <div className="d-flex align-items-center p-5">
              <Image width={650} src={StatsImage} alt="" className="img-fluid" placeholder="blur" />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
