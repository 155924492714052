import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import Image from 'next/image';
import StatsSecureImage from '../../../../public/statics/images/stats/secure.png';

export const StatsSafeTrustedSecure: React.FC = () => {
  return (
    <>
      <Container className="text-center my-5 py-5">
        <Row>
          <Col
            md={6}
            className="text-center text-md-start d-flex flex-column align-items-center align-items-md-start justify-content-center order-2 order-lg-1"
          >
            <p className="smaller-header">
              <FormattedMessage id="web.stats.safe-trusted-secure.title" defaultMessage="Safe, trusted, private" />
            </p>
            <p className="lead my-1">
              <FormattedMessage
                id="web.stats.safe-trusted-secure.content.1"
                defaultMessage="All your data is stored locally on your device."
              />
            </p>
          </Col>
          <Col md={4} className="d-flex align-items-center order-1 order-lg-2">
            <div className="d-flex align-items-center p-5">
              <Image src={StatsSecureImage} alt="" className="img-fluid" width={650} placeholder="blur" />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
